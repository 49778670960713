const cleControle = function () {
  // la date est codée en base 31
  const dte = new Date()
  dte.setHours(12)
  const n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  const cle = n.toString(31)
  return cle
}
export function toFrench(date: string) {
  if (date == null) {
    return ''
  }
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}
function dateFromString(dateISO: string): Date {
  // 'yyyy-mm-dd' vers objet Date
  if (dateISO == null) return new Date()
  const [year, month, day] = dateISO.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
function stringFromDate(dte: Date): string {
  // objet Date vers 'yyyy-mm-dd'
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  return `${y}-${mmo}-${dd}`
}
function lundi(uneDate: string): string {
  const dte = dateFromString(uneDate)
  let jsem = dte.getUTCDay() - 1
  if (jsem < 0) jsem += 7
  const lundi = new Date(dte.getFullYear(), dte.getMonth(), dte.getDate() - jsem)
  return stringFromDate(lundi)
}
export function getWeekOfYear(date: Date) {
  const MILLISECONDS_IN_DAY = 86400000
  // Remove time components of date
  const thursday = new Date(date.getFullYear(), date.getMonth(), date.getDate())

  // Change date to Thursday same week
  thursday.setDate(thursday.getDate() - ((thursday.getDay() + 6) % 7) + 3)

  // Take January 4th as it is always in week 1 (see ISO 8601)
  const firstThursday = new Date(thursday.getFullYear(), 0, 4)

  // Change date to Thursday same week
  firstThursday.setDate(firstThursday.getDate() - ((firstThursday.getDay() + 6) % 7) + 3)

  // Check if daylight-saving-time-switch occurred and correct for it
  const ds = thursday.getTimezoneOffset() - firstThursday.getTimezoneOffset()
  thursday.setHours(thursday.getHours() - ds)

  // Number of weeks between target Thursday and first Thursday
  const weekDiff = (thursday.valueOf() - firstThursday.valueOf()) / (MILLISECONDS_IN_DAY * 7)
  return 1 + Math.floor(weekDiff)
}
function sqlFormat(annee: number, mois: number, jour: number) {
  const a = annee.toString()
  let m = mois.toString()
  if (m.length === 1) m = '0' + m
  let j = jour.toString()
  if (j.length === 1) j = '0' + j
  return a + '-' + m + '-' + j
}

export function formaSQL(dte: string) {
  if (dte === null) return ''
  if (dte === undefined) return ''
  let [y, m, j] = dte.split('-')
  if (m.length === 1) m = '0' + m
  if (j.length === 1) j = '0' + j
  return y + '-' + m + '-' + j
}

export function sqlDatetime(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? '0' : '') + h
  const mmn = (mn < 10 ? '0' : '') + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}

export function francaiseDatetime(dte: Date | string): string {
  dte = new Date(dte)
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? '0' : '') + h
  const mmn = (mn < 10 ? '0' : '') + mn

  return `${dd}/${mmo}/${y} ${hh}:${mmn}`
}

export function francaiseDate(dte: Date | string): string {
  dte = new Date(dte)
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  return `${dd}/${mmo}/${y}`
}

export function sqlDate(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  return `${y}-${mmo}-${dd}`
}
function serializeQuery(params: any, prefix: any) {
  const query: any = Object.keys(params).map((key) => {
    const value = params[key]

    if (params.constructor === Array) {
      key = `${prefix}[]`
    } else if (params.constructor === Object) {
      key = prefix ? `${prefix}[${key}]` : key
    }
    if (typeof value === 'object') return serializeQuery(value, key)
    else return `${key}=${encodeURIComponent(value)}`
  })
  return [].concat(...query).join('&')
}
function openURL(url: string) {
  const open = window.open

  const win = open(url, '_blank')

  if (win) {
    win.focus()
    return win
  }
}
const variables = {
  version: '1.0',
  authentifie: false,
  autorisation: [] as any,
  utilisateur: '',
  jourTableau: '',
  jour2Tableau: '',
  debug: process.env.NODE_ENV === 'development'
}

function dateVersChaine(dte: any) {
  return dte.toISOString().substr(0, 10)
}
export { cleControle, dateFromString, stringFromDate, sqlFormat, variables, serializeQuery, openURL, lundi, dateVersChaine }
